<template>
  <div class="" v-if="isLoading" v-html="loadingSkeleton"></div>
  <div class="row" v-else>
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
      <div class="v16-description" style="color: #73738D;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 28px;"> Your Quiz Statistics</div>
<div class="d-flex gap align-items-center">
  <button @click="$refs.resetChartAnalytics.openResetModal()" class="export-button"> <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M2.63263 11.243C3.11896 12.6233 4.04073 13.8081 5.25906 14.619C6.47739 15.4299 7.92627 15.8229 9.3874 15.7388C10.8485 15.6548 12.2427 15.0981 13.36 14.1528C14.4772 13.2075 15.257 11.9247 15.5817 10.4978C15.9065 9.07081 15.7586 7.57697 15.1605 6.24132C14.5623 4.90568 13.5463 3.8006 12.2654 3.09258C10.9846 2.38455 9.5083 2.11195 8.05903 2.31584C5.61219 2.66007 3.9956 4.43357 2.25 6M2.25 6V1.5M2.25 6H6.75" stroke="#73738D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Reset Data</button>
</div>
      </div>
      <div class="card text-left  analytics-box mt-4">
        <div class="card-body">
          <div class="">
            <quizStatistics ref="apexchart" :graphData="graphAnalysis" :leads="totalLeads" />
          </div>
        </div>
      </div>
    </div>
    <ResetAnalytics
      id="resetChartAnalytics"
      :quizzes="quizzes"
      :isResetting="isLoadingReset"
      ref="resetChartAnalytics"
      @reset="resetChart"></ResetAnalytics>
  </div>
</template>

<script>

import quizStatistics from "./quizStatistics.vue";
import ResetAnalytics from "./ResetAnalytics.vue";
export default {
  props: ["quizzes","isLoading", "graphAnalysis", "totalLeads","isV1"],
  data() {
    return {
      isLoadingReset: false,
      isQuestionLoading: true,
      AnswerStats: [],
    };
  },
  methods: {
 
    async resetChart(quizIds) {
      this.isLoadingReset = true;

      // const response =
      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "post",
        resource: "analysis",
        method: this.isV1?"resetChartOld":"resetChart",
        params: { quizId: quizIds },
      });
      if (response && response.status) {
        this.$refs.resetChartAnalytics.closeResetModal();
        this.$toasted.show("Stats has been reset.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        this.$emit('loadGraph',this.isV1);
      } else {
        this.$toasted.show("Something went wrong.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
      this.isLoadingReset = false;
    },
  },
  computed: {
    loadingSkeleton() {
      return `
          <div class="row">
            <div class="col-12">
              <div class="card text-left analytics-box mt-4 w-100">
                <div class="card-body">
                  <div
                    class="bg-preload mr-4"
                    style="height: 40px; width: 100px; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                 
                  <div
                    class="bg-preload mt-3"
                    style="height: 5px; width: 100%; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                  <div class="mt-4">
                    <div
                      class="bg-preload"
                      style="height: 500px; width: 100%; border-radius: 4px"
                    >
                      <div class="into-preload"></div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>`;
    },
  },
  components: {
    ResetAnalytics,
    quizStatistics,
  },
  mounted() {
  },
};
</script>

<style scoped>
 .analytics-box{
  border-radius:  8px;
border: 1px solid #EDEDED;
background:  #FFF;

/* Shadow/sm */
box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
 }
 .export-button{
  border-radius: 6px;
border: 1px solid #E1E3E6;
background: #F6F6F8;
padding:12px;
text-align: center;
color: #73738D;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 19.5px */
 }
</style>
